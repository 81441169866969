import "./App.css";
import axios from 'axios'
import { Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import StepOne from "./Components/Forms/StepOne";
import StepTwo from "./Components/Forms/StepTwo";
import StepThree from "./Components/Forms/StepThree";

function App() {
  //state for data
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(true);
  //state for steps
  const [step, setstep] = useState(1);

  //state for form data
  const [formData, setFormData] = useState({
    brandId: "",
    designId: "",
    productId: ""
  })

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setstep(step + 1);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setstep(step - 1);
  };

  // handling form input data by taking onchange value and updating our previous form data state
  const handleInputData = (input, value) => {
    setFormData(prevState => ({
      ...prevState,
      [input]: value
    }));
  }

  const submitFormData = (item) => {
    window.parent.postMessage({id_msg: 'SELECT_MATERIAL', mat_code: item.id}, '*');
    setstep(1);
  }

  const closeModal = () => {
    window.parent.postMessage({id_msg: 'CLOSE_MODAL'}, '*');
  }

  const fetchData = async () => {
    setLoading(true);

    try {
      const {data: response} = await axios.get(
        `${process.env.REACT_APP_PRODUCTS_API}&filter[limit]=500`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type":  "application/json"
          }
        }
      );
      setResponseData(response.products);
    } catch(error) {
      console.error(error.message);
    }

    setLoading(false);
  }

  const brandCollection = [
    {
      id: 'Arauco Melamina',
      name: 'Arauco Melamina',
      formats: ['MDF 1.22m x 2.44m','PBO 1.22m x 2.44m'],
      src: '/images/brands/arauco_melamina_square.jpeg'
    },
    {
      id: 'Melamina Vesto',
      name: 'Melamina Vesto',
      formats: ['MDP 1.83m x 2.50m','MDF 1.83m x 2.44m.'],
      src: '/images/brands/melamina_vesto_square.png'
    }
  ]

  useEffect(() => {
    fetchData();
  }, [])

// javascript switch case to show different form in each step
  switch (step) {
    // case 1 to show stepOne form and passing nextStep, prevStep, and handleInputData as handleFormData method as prop and also formData as value to the fprm
    case 1:
      return (
        <div className="App">
          <div className="mt-5 px-3 py-2">
            <Row>
              <Col>
                {loading && <div>Loading</div>}
                {!loading && (
                  <StepOne nextStep={nextStep} handleFormData={handleInputData} handleModal={closeModal} values={formData} collection={brandCollection} />
                )}
              </Col>
            </Row>
          </div>
        </div>
      );
    case 2:
      return (
        <div className="App">
          <div className="my-5 p-3">
            <Row>
              <Col>
                <StepTwo nextStep={nextStep} prevStep={prevStep} handleFormData={handleInputData} handleModal={closeModal} values={formData} collection={responseData} />
              </Col>
            </Row>
          </div>
        </div>
      );
    case 3:
      return (
        <div className="App">
          <div className="my-5 p-3">
            <Row>
              <Col>
                <StepThree nextStep={nextStep} prevStep={prevStep} handleFormData={submitFormData} handleModal={closeModal} values={formData} collection={responseData} />
              </Col>
            </Row>
          </div>
        </div>
      );
    default:
      return (
        <div className="App"></div>
      );
  }
}

export default App;
