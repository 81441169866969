import React from "react";
import { Card, Button, Row, Col, Navbar } from "react-bootstrap";

// creating functional component ans getting props from app.js and destucturing them
const StepOne = ({ nextStep, handleFormData, handleModal, values, collection }) => {

  // after form submit validating the form data using validator
  const submitFormData = (item) => {
    handleFormData("brandId", item.id)
    nextStep();
  };

  const closeModal = () => {
    handleModal();
  };

  return (
    <div>
      <Navbar variant="dark" fixed="top" className="bg-white">
        <Row className="w-100 bg-white">
          <Col></Col>
          <Col>
            <h4>
              Selecciona una marca
            </h4>
          </Col>
          <Col>
            <Button variant="outline-secondary" className="btn-close float-end" onClick={() => closeModal()}></Button>
          </Col>
        </Row>
      </Navbar>
      <Row className="mt-0">
        {collection.map(item => {
          return (
            <Col sm={6} key={item.id}>
              <Card>
                <Card.Img variant="top" src={item.src} />
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                    <div>
                      <strong>Formatos</strong>
                      <ul className="list-unstyled">
                      {
                        item.formats.map(format => {
                          return (
                            <li key={format}>
                              {format}
                            </li>
                          );
                        })
                      }
                      </ul>
                    </div>
                  <Button variant="outline-dark" className="mt-0" onClick={() => submitFormData(item)}>
                    Seleccionar
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            );
          }
        )}
        </Row>
    </div>
  );
};

export default StepOne;
