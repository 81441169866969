import React from "react";
import { Card, Button, Row, Col, Navbar } from "react-bootstrap";

// creating functional component ans getting props from app.js and destucturing them
const StepThree = ({ nextStep, handleFormData, prevStep, handleModal, values, collection }) => {

  const submitFormData = (item) => {
    handleFormData(item)
  };

  const closeModal = () => {
    handleModal();
  };

  const filteredCollection = collection.filter(d => d.images.length > 0 && `${d.title.split('-')[0]}-${d.title.split('-')[1]}` === values.designId && d.brand === values.brandId);

  return (
    <div>
      <Navbar variant="dark" fixed="top" className="bg-white">
        <Row className="w-100 py-2 ps-3 bg-white">
          <Col>
            <Button variant="primary" onClick={prevStep} className="float-start">
              Volver
            </Button>
          </Col>
          <Col>
            <h4>
              Selecciona un sustrato
            </h4>
          </Col>
          <Col>
            <Button variant="outline-secondary" className="btn-close float-end" onClick={() => closeModal()}></Button>
          </Col>
        </Row>
      </Navbar>
      <Row className="mt-4">
        {filteredCollection.map(item => {
          return (
            <Col sm={6} key={item.id} className="my-3">
              <Card>
                <Card.Img variant="top" src={item.images[0].src}/>
                <Card.Body>
                  <Card.Title>{item.title}</Card.Title>
                  <Button variant="outline-dark" className="mt-2" onClick={() => submitFormData(item)}>
                    Seleccionar
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            );
          }
        )}
        </Row>
    </div>
  );
};

export default StepThree;
