import React from "react";
import { Card, Button, Row, Col, Navbar } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PlaceholderImage from "../../images/placeholder.png";

// creating functional component ans getting props from app.js and destucturing them
const StepTwo = ({ nextStep, handleFormData, prevStep, handleModal, values, collection }) => {

  // after form submit validating the form data using validator
  const submitFormData = (item) => {
    const designId = `${item.title.split('-')[0]}-${item.title.split('-')[1]}`;
    handleFormData("designId", designId)
    nextStep();
  };

  const closeModal = () => {
    handleModal();
  };

  const collection_names = [];

  const collection_filtered = collection.filter(item => {
    const title = item.title.split(' - ')[1].normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    if (!collection_names.includes(title)){
      collection_names.push(title);
      return item;
    }
  });

  const collection_sorted = collection_filtered.sort((a, b) => {
    if (a.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "") < b.title.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) {
      return -1;
    }
  });

  return (
    <div>
      <Navbar variant="dark" fixed="top" className="bg-white">
        <Row className="w-100 py-2 ps-3 bg-white">
          <Col>
            <Button variant="primary" onClick={prevStep} className="float-start">
              Volver
            </Button>
          </Col>
          <Col>
            <h4>
              Selecciona un diseño
            </h4>
          </Col>
          <Col>
            <Button variant="outline-secondary" className="btn-close float-end" onClick={() => closeModal()}></Button>
          </Col>
        </Row>
      </Navbar>
      <Row className="mt-3">
        {collection_sorted.filter(d => d.images.length > 0 && d.brand === values.brandId).map(item => {
          return (
            <Col sm={4} key={item.id} className="my-3">
              <Card>
                <LazyLoadImage src={item.images[0].src}
                  alt={item.title}
                  className="card-img-top"
                  height={220}
                  placeholdersrc={PlaceholderImage}
                  effect="blur"
                />
                <Card.Body>
                  <Card.Title>{item.title.split(' - ')[1]}</Card.Title>
                  <Button variant="outline-dark" className="mt-2" onClick={() => submitFormData(item)}>
                    Seleccionar
                  </Button>
                </Card.Body>
              </Card>
            </Col>
            );
          }
        )}
        </Row>
    </div>
  );
};

export default StepTwo;
